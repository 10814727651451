import { Injectable, Inject, inject, InjectionToken, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuEntry } from '../models';
@Injectable({ providedIn: 'root' })
export class MenuService {
  public menu: { [identifier: string]: MenuEntry } = {};

  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  loadMenu() {
    return this.http.get<MenuEntry[]>(`${this.BASE_URL}api/menu`);
  }

  init() {}

  isAbsolute(url: string) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
}
